.TextModal-paragraph {
  text-align: justify;
}

.indented {
  text-indent: var(--spacing-xl);
}

.modal .ant-modal-content {
  background-color: var(--color-nav-background);
  color: var(--color-text-dark);
  max-width: 800px;
  margin: 0 auto;
}

.TextModal-links {
  display: flex;
  gap: var(--spacing-xxl);
  margin: var(--spacing-xl) var(--spacing-xxl);
  justify-content: center;
  align-items: center;
}

.ant-modal-close-icon {
  color: var(--color-text-dark);
}

.link {
  background-color: var(--color-primary);
  color: var(--color-text);
  padding: var(--spacing-sm) var(--spacing-xl);
  font-weight: bold;
  text-decoration: none;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease;
  flex-basis: 100%;
  text-align: center;
}

.link:hover {
  background-color: var(--color-primary-hover);
}

.TextModal-close {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: var(--color-text-dark);
  font-size: var(--font-size-base);
  margin: 0 auto;
  text-align: center;
  display: block;
}

.TextModal-close:hover {
  color: #3b82f6;
}

@media (max-width: 850px) {
  .TextModal-links {
    display: block;
    margin: var(--spacing-xl) var(--spacing-md);
  }

  .link {
    display: block;
    margin-bottom: var(--spacing-md);
  }
}
