/* General Styling */
.App {
  color: var(--color-text);
  background-color: var(--color-background);
  text-align: center;
  padding-top: var(--spacing-xxl);
}

/* Blurb Section */
.Blurb-section {
  background-color: var(--color-background-secondary);
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: var(--spacing-xl);
  /* padding: 0 4rem; */
}

.Book-blurb {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-base);
  color: var(--color-text-dark);
  text-align: justify;
  padding-bottom: var(--spacing-xl);
  max-width: var(--max-width-content);
  background-color: #fff;
  padding: var(--spacing-xl);
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-xl);
}

/* Buy Links Section */
.Buy-section {
  display: flex;
  justify-content: center;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-xl);
  display: none;
}

.link {
  background-color: var(--color-primary);
  color: var(--color-text);
  padding: var(--spacing-sm) var(--spacing-xl);
  font-weight: bold;
  text-decoration: none;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease;
  flex-basis: 100%;
  text-align: center;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-size: var(--font-size-base);
  font-family: var(--font-primary);
}

.link:hover {
  background-color: var(--color-primary-hover);
}

/* About the Author Section */
.Author-section {
  width: 100%;
  margin: 0 auto;
  color: var(--color-text);
  background-color: var(--color-background);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  font-size: var(--spacing-xl);
  color: var(--color-primary);
  margin-bottom: var(--spacing-xl);
  text-align: center;
}

.Author-bio {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-base);
  color: var(--color-text-dark);
  max-width: var(--max-width-content);
  text-align: justify;
  background-color: #fff;
  padding: var(--spacing-xl);
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-xl);
}

/* Copyright Section */
.Copyright-section {
  background-color: var(--color-background);
  font-size: 0.9rem;
  color: var(--color-text-secondary);
  padding: var(--spacing-md) var(--spacing-xxl);
  border-top: 1px solid var(--color-border);
  line-height: 1.5rem;
}

@media (max-width: 768px) {
  .Buy-section {
    display: block;
  }
}
