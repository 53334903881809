/* General Navigation Bar Styles */
.NavBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-nav-background-secondary);
  text-align: center;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* NavBar Links - Desktop View */
.NavBar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavBar li {
  flex: 1;
}

.nav-link {
  background-color: var(--color-nav-background-secondary);
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: block;
  width: 100%;
  padding: var(--spacing-lg) 0;
  text-align: center;
  text-decoration: none;
  color: var(--color-primary);
  font-weight: bold;
  font-size: var(--font-size-base);
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.nav-link:hover {
  background-color: var(--color-nav-background-secondary-hover);
  color: var(--color-primary-hover);
}

.Menu-toggle {
  display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .NavBar ul {
    flex-direction: column;
    display: none;
    background-color: var(--color-nav-background-secondary);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .NavBar ul.show {
    display: flex;
  }

  .NavBar li {
    flex: none;
    width: 100%;
  }

  .NavBar a {
    padding: var(--spacing-md) 0;
  }

  .Menu-toggle {
    display: block;
    font-size: var(--font-size-xl);
    color: var(--color-primary);
    cursor: pointer;
    background: none;
    border: none;
    margin: var(--spacing-xs);
  }
}
