@import './variables.css';

:root {
  font-family: Inter, sans-serif;
  font-feature-settings:
    'liga' 1,
    'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

body {
  font-family: Inter, sans-serif;
  font-feature-settings:
    'liga' 1,
    'calt' 1; /* fix for Chrome */
  margin: 0;
  background-color: black;
}
