/* Cover Section */
.Cover-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto var(--spacing-xl);
  row-gap: var(--spacing-xxl);
  width: 100vw;
  background-color: var(--color-background);
}

.full-height {
  min-height: 100vh;
}

.Cover-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background);
}

.Cover-image {
  width: 40vw;
  margin: 0 auto;
}

/* Text Content */
.Text-content {
  width: 60vw;
  max-width: 500px;
  margin: 0 auto;
  text-align: right;
}

.Book-title {
  font-size: var(--font-size-title);
  color: var(--color-primary);
  margin: var(--spacing-xs) 0;
  text-transform: uppercase;
  padding-bottom: var(--spacing-md);
  font-family: var(--font-display);
  font-weight: 400;
  font-style: normal;
  letter-spacing: var(--letter-spacing-title);
  line-height: 1;
}

.Book-tagline {
  font-size: var(--font-size-xl);
  color: var(--color-text-secondary);
  margin: 0;
  text-align: right;
  padding-right: 10px;
}

.Book-author {
  font-size: var(--font-size-base);
  color: var(--color-text);
  padding-right: 10px;
}

/* Cover Image */
.Book-cover-image {
  max-width: 300px;
  height: auto;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin: 0 auto var(--spacing-xl) auto;
}

@media (max-width: 768px) {
  .Cover-content {
    flex-direction: column;
  }

  .Cover-section {
    row-gap: var(--spacing-md);
  }

  .Text-content {
    text-align: center;
  }

  .Book-title {
    text-align: center;
  }

  .Book-tagline {
    text-align: center;
    padding-right: 0;
  }

  .Book-author {
    text-align: center;
    padding-bottom: var(--spacing-xl);
    padding-right: 0;
  }

  .Cover-image {
    width: 100vw;
  }
}
