.Buy {
  color: var(--color-text);
  background-color: var(--color-background);
  text-align: center;
  padding-top: var(--spacing-xxl);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Buy-container {
  /* margin: 0 auto; */
  max-width: 80vw;
}

.BuySection-container {
  padding: var(--spacing-xl) 0;
  width: 100vw;
  margin: 0 auto;
  text-align: center;
}

.BuySection-title {
  text-align: center;
  color: var(--color-text-dark);
  margin-bottom: var(--spacing-xxl);
  font-size: var(--font-size-xxl);
}

.BuySection-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-xl);
  padding: 0 var(--spacing-xl);
}

.Buy-home {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.Buy-home-link {
  display: block;
  margin: var(--spacing-xl) auto;
  font-size: var(--font-size-lg);
  text-decoration: none;
  color: var(--color-text);
  font-weight: bold;
  transition: all 0.3s ease;
  text-align: center;
}

.Buy-home-link:hover {
  color: var(--color-primary);
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-primary);
  color: var(--color-text);
  padding: var(--spacing-md);
  font-weight: bold;
  text-decoration: none;
  border-radius: var(--border-radius);
  transition: all 0.3s ease;
  text-align: center;
  justify-content: center;
}

.link:hover {
  background-color: var(--color-primary-hover);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.link-disabled:hover {
  background-color: var(--color-primary);
  transform: none;
  box-shadow: none;
  cursor: default;
}

.link-title {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-xs);
}

.link-subtitle {
  font-size: var(--font-size-sm);
  opacity: 0.9;
  font-weight: normal;
}

.link-disabled-text {
  font-size: var(--font-size-sm);
  color: var(--color-text);
  margin-top: var(--spacing-xs);
}

@media (max-width: 850px) {
  .BuySection-links {
    grid-template-columns: 1fr;
    padding: 0 var(--spacing-md);
  }
}
