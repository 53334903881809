@font-face {
  font-family: 'Lino Gothic';
  src:
    local('Lino Gothic'),
    url(./fonts/LinoGothic-Vector.otf) format('opentype');
}

:root {
  /* Dark Theme Colors */
  --color-primary: #2b6fc3;
  --color-primary-hover: #1e5698;
  --color-background: #121212; /* Dark background */
  --color-background-secondary: #1e1e1e; /* Slightly lighter dark background */
  --color-background-secondary-hover: #2a2a2a; /* Hover effect background */
  --color-text: #e0e0e0; /* Light text for readability on dark background */
  --color-text-dark: #212529; /* Dark text for readability on light background */
  --color-text-secondary: #a3a3a3; /* Muted light text for secondary content */
  --color-border: #3a3a3a; /* Dark gray border for subtle outlines */

  --color-nav-background: #f8f9fa; /* Dark background */
  --color-nav-background-secondary: #e9ecef; /* Slightly lighter dark background */
  --color-nav-background-secondary-hover: #dee2e6; /* Hover effect background */

  /* Typography */
  --font-primary: Inter, sans-serif;
  --font-display: 'Lino Gothic', sans-serif;
  --font-size-base: 1rem;
  --font-size-lg: 1.1rem;
  --font-size-xl: 1.2rem;
  --font-size-title: 5.6rem;
  --line-height-base: 1.6;
  --letter-spacing-title: 0.2rem;

  /* Spacing */
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.7rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 4rem;

  /* Layout */
  --max-width-content: 80vw;
  --border-radius: 5px;
  --box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  :root {
    --font-size-title: 3rem;
  }
}
